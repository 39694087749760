function ShowOkModalPopup(dialogSelectorId, confirmSelectorId, url) {

    $("#" + dialogSelectorId).dialog("destroy");
    var modalPopup = $("#" + confirmSelectorId);
    modalPopup.remove();
    modalPopup.html();
    modalPopup.dialog({
        modal: true,
        resizable: false,
        buttons: {
            "Ok": function () {
                window.location.href = url;
            }
        }
    });
}

function ShowOkModalPopup(dialogSelectorId, confirmSelectorId, url, message) {

    $("#" + dialogSelectorId).dialog("destroy");
    var modalPopup = $("#" + confirmSelectorId);
    modalPopup.remove();
    modalPopup.html(message);
    modalPopup.dialog({
        modal: true,
        resizable: false,
        buttons: {
            "Ok": function () {
                window.location.href = url;
            }
        }
    });
}

function ShowCloseOkModalPopup(dialogSelectorId, confirmSelectorId, url, message) {

    $("#" + dialogSelectorId).dialog("destroy");
    var modalPopup = $("#" + confirmSelectorId);
    modalPopup.remove();
    modalPopup.html(message);
    modalPopup.dialog({
        modal: true,
        resizable: false,
        buttons: {
            "Ok": function () {
                window.location.href = url;
            }
        },
        beforeClose: function (event, ui) {
            window.location.href = url;
        }
    });
}

function ShowModalPopup(dialogSelectorId, confirmSelectorId, message) {
    $("#" + dialogSelectorId).dialog("destroy");
    var modalPopup = $("#" + confirmSelectorId);
    modalPopup.remove();
    modalPopup.html(message);
    modalPopup.dialog({
        modal: true,
        resizable: false
    });
}

/* show modal form
dialogSelectorId - the id of the dialog (dialog:ui-dialog),
formSelectorId - the id of the div (dialog-form)
*/
function ShowSummaryModalForm(dialogSelectorId, formSelectorId, nWidth, nHeight) {
    if (nHeight == "undefined" || nHeight == null) {
        nHeight = 300;
    }

    if (nWidth == "undefined" || nWidth == null) {
        nWidth = 680;
    }

    $("#" + dialogSelectorId).dialog("destroy");
    var modalPopup = $("#" + formSelectorId);
    modalPopup.dialog({
        autoOpen: false,
        minHeight: 300,
        height: nHeight,
        width: nWidth,
        minWidth: 600,
        modal: true,
        resizable: false,
        buttons: {
            "Close": function () {
                $(this).dialog("close");
            }
        }
    });
    $("#ui-dialog-content").addClass("grid_16");
    return modalPopup;
}


function ShowSummaryModalFormNoClose(dialogSelectorId, formSelectorId) {
    $("#" + dialogSelectorId).dialog("destroy");
    var modalPopup = $("#" + formSelectorId);
    modalPopup.dialog({
        autoOpen: false,
        minHeight: 300,
        height: 300,
        width: 680,
        minWidth: 600,
        resizable: false,
        modal: true,
        title: "Information"
    });
    $("#ui-dialog-content").addClass("grid_16");
    return modalPopup;
}

function ShowAlertModalPopup(message, title) {
    var modalSelector = "#" + ModalPopup_Type.Alert.Id;

    $(modalSelector).dialog("destroy");
    $(modalSelector).remove();

    if ($(modalSelector).length == 0) {
        $('body').append("<div id='" + ModalPopup_Type.Alert.Id + "' title='" + GetModalPopupTitle(title, ModalPopup_Type.Alert) + "'></div>");
    }

    $(modalSelector).html(message);
    $(modalSelector).dialog({
        modal: true,
        resizable: false,
        buttons: {
            "Ok": function () {
                $(this).dialog("close");
                return false;
            }
        }
    });

    $(modalSelector).dialog("open");
    return false;
}

//  Method to show Confirm modal form with Ok and Cancel button 
//  message: message to show in the dialog box
//  ConfirmCallback: A callback method to be called on confirm.
//  title: title to be displayed
function ShowConfirmModalPopup(message, confirmCallback, title) {
    ConfirmModalPopup(message, confirmCallback, title, ModalPopup_Button.OkCancel);
}

//  Method to show Confirm modal form with Yes and No button
//  message: message to show in the dialog box
//  ConfirmCallback: A callback method to be called on confirm.
//  title: title to be displayed
function ShowConfirmModalPopupYesNo(message, confirmCallback, title) {
    ConfirmModalPopup(message, confirmCallback, title, ModalPopup_Button.YesNo);
}

//  Method to show Confirm modal form with Ok button only.
//  message: message to show in the dialog box
//  ConfirmCallback: A callback method to be called on confirm.
//  title: title to be displayed
function ShowConfirmModalPopupOk(message, confirmCallback, title) {
    ConfirmModalPopup(message, confirmCallback, title, ModalPopup_Button.Ok);
}

//  message: message to show in the dialog box
//  ConfirmCallback: A callback method to be called on confirm.
//  title: title to be displayed
function ConfirmModalPopup(message, confirmCallback, title, modalPopup_Button) {
    var modalSelector = "#" + ModalPopup_Type.Confirm.Id;

    $(modalSelector).dialog("destroy");
    $(modalSelector).remove();

    if ($(modalSelector).length == 0) {
        $('body').append("<div id='" + ModalPopup_Type.Confirm.Id + "' title='" + GetModalPopupTitle(title, ModalPopup_Type.Confirm) + "'></div>");
    }

    var buttons = [];

    buttons.push({
        text: modalPopup_Button.Action,
        click: function () {
            $(this).dialog("close");
            if (confirmCallback != undefined && confirmCallback != null) {
                confirmCallback();
            }
        }
    });

    if (modalPopup_Button.ActionCancel != "") {
        buttons.push({
            text: modalPopup_Button.ActionCancel,
            click: function () {
                $(this).dialog("close");
                $(".toHide").show();
                return false;
            }
        });
    }

    $(modalSelector).html(message);
    $(modalSelector).dialog({
        modal: true,
        resizable: false,
        buttons: buttons
    });

    $('.ui-dialog-buttonpane button:contains(No)').removeClass('ui-state-default').addClass('secondaryBtn');
    $('.ui-dialog-buttonpane button:contains(Cancel)').removeClass('ui-state-default').addClass('secondaryBtn');
    $(modalSelector).dialog("open");
    return false;
}

function GetModalPopupTitle(title, ModalPopup_Type) {
    return (title == undefined || title == "") ? ModalPopup_Type.Title : title;
}

var ModalPopup_Type = {
    Alert: { Title: "Alert", Id: "divAlertPopup" },
    Confirm: { Title: "Confirmation Message", Id: "divConfirmPopup" }
};

var ModalPopup_Button = {
    YesNo: { Action: "Yes", ActionCancel: "No" },
    OkCancel: { Action: "Ok", ActionCancel: "Cancel" },
    Ok: { Action: "Ok", ActionCancel: "" }
};

// This function will open a dialog popup for the given messages.
// message : message to show in the dialog box
// popUpWidth : width of the popup to be displyed
// errorOkCallback : event to be fired on click of OK button in case of Error
// informationOkCallback : event to be fired on click of OK button in case of Information
// warningOkCallback : event to be fired on click of OK/Submit button in case of Warning
// warningCancelCallback : event to be fired on click of Cancel button in case of Warning
function GetMessageModalPopUp(message, popUpWidth, errorOkCallback, informationOkCallback, warningOkCallback, warningCancelCallback) {

    $("#messageDialog").dialog("destroy");
    $("#messageDialog").remove();

    $('body').append("<div id='messageDialog' title='" + $(message).find('#hfMessageType').val() + "(s)'></div>");

    $("#messageDialog").dialog({
        autoOpen: false,
        modal: true,
        width: popUpWidth,
        resizable: false,
        closeOnEscape: false,
        zIndex: 9000
    });

    $('#messageDialog').html(message);

    var type = $("#hfMessageType").val();

    $("#chkWarningAccept").bind("click", function (event) {
        TogggleWarningCheckSubmit();
    });

    if (type == "Warning") {
        $("#btnStop").bind("click", function (event) {
            $('#messageDialog').dialog('close');
            if (warningCancelCallback != undefined && warningCancelCallback != null) {
                warningCancelCallback();
            }
        });
        $("#btnProceed").bind("click", function (event) {
            $('#messageDialog').dialog('close');
            if (warningOkCallback != undefined && warningOkCallback != null) {
                warningOkCallback();
            }
        });
    }

    if (type == "Information") {
        $("#btnProceed").bind("click", function (event) {
            $('#messageDialog').dialog('close');
            if (informationOkCallback != undefined && informationOkCallback != null) {
                informationOkCallback();
            }
        });
        $("<br/><hr/>").insertAfter(".simpleTable");
        $(".simpleTable").removeClass("simpleTable");
    }

    if (type == "Error") {
        $("#btnProceed").bind("click", function (event) {
            $('#messageDialog').dialog('close');
            if (errorOkCallback != undefined && errorOkCallback != null) {
                errorOkCallback();
            }
        });
    }

    $("#messageDialog").dialog("open");
}

function TogggleWarningCheckSubmit() {
    if ($("#chkWarningAccept").attr('checked')) {
        $("#btnProceed").removeAttr("disabled").removeClass("disabled");
    }
    else {
        $("#btnProceed").attr("disabled", "disabled").addClass("disabled");
    }
}

$(document).ready(function () {
    $("#progress_steps").empty();
    var progress_steps = [
        'Your Quote',
        'Your Details',
        'Bank Details',
        'Review',
        'Sign Agreement'];

    var progress_steps_urls = [
        '/Application/YourQuote',
        '/Application/YourDetails',
        '/Finance/PaymentDetails',
        '/Application/ConfirmDetails',
        '/Application/CreditAgreement',
        '/Application/Confirmation'
    ];

    var active_key = 0;
    $.each(progress_steps_urls, function (index, val) {
        if (val == document.location.pathname)
            active_key = index;
    });


    $.each(progress_steps, function (index, label) {
        var tagClass = '';
        var stepNumber = index + 1;
        var icon = "<span class='number_count'>" + stepNumber + "</span>";
        if (index == active_key) {
            tagClass = "active";
        } else if (index < active_key) {
            tagClass = "complete";
            icon = "<span class='number_count'><i class='fa fa-check'></i></span>";
        }
        $("#progress_steps").append("<li class=" + tagClass + ">" + icon + " <span class='sr-only'>Step " + stepNumber + "</span> <span class='progress_step'>" + label + "</span></li>");

    });
});

// This function will format the currency with comma separated
function fnFormatCurrency(value) {
    if (value != undefined && value != '') {
        value = value.replace(/,/g, '');
        value = parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return value;
    }
}
function fnFormatAnnualCurrency(value) {
    if (value != undefined && value != '') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return value;
    }
}